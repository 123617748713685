import {
  assert,
  withValidation,
  composeSDKFactories,
  reportWarning,
  messages,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import {
  ISelectionTagsListSDKFactory,
  ISelectionTagsListProps,
  ISelectionTagsListSDK,
  ISelectionTagsImperativeActions,
  ISelectionTagsListOption,
} from '../SelectionTagsList.types';
import {
  disablePropsSDKFactory,
  createRequiredPropsSDKFactory,
  clickPropsSDKFactory,
  createValidationPropsSDKFactory,
  createStylePropsSDKFactory,
  changePropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  createInputValidator,
  composeValidators,
  validateRequiredArray,
  InputValidator,
} from '../../../core/corvid/inputUtils';

import {
  validationSchema,
  customValidators,
} from './SelectionTagsList.validators';

const selectionTagsValidator: InputValidator<
  ISelectionTagsListProps,
  ISelectionTagsImperativeActions
> = createInputValidator(
  composeValidators<ISelectionTagsListProps>([validateRequiredArray]),
);

const rejectOptionsWithoutRequiredProperties = (
  options: Array<ISelectionTagsListOption>,
) => {
  return options.filter((item, index) => {
    const isWithoutProperty =
      assert.isNil(item.value) && assert.isNil(item.label);

    if (isWithoutProperty) {
      reportWarning(
        messages.invalidOption({
          propertyName: 'SelectionTags.Option',
          index,
          wrongValue: options[index],
        }),
      );

      return false;
    }

    return true;
  });
};

const matchValuesToOptions = (
  options: ISelectionTagsListProps['options'],
  values: ISelectionTagsListProps['value'],
) => {
  const optionsValue = options.map(option => option.value);
  return values.filter(value => optionsValue.includes(value));
};

const selectionTagsListSDKFactory: ISelectionTagsListSDKFactory = api => {
  const {
    setProps,
    props,
    platformUtils: { linkUtils },
    metaData,
    getSdkInstance,
  } = api;

  const runValidator = () => {
    selectionTagsValidator.validate({
      viewerSdkAPI: api,
      showValidityIndication: true,
    });
  };

  const setValue = (nextValue: ISelectionTagsListProps['value']) => {
    const { options } = props;

    if (assert.isNil(nextValue)) {
      nextValue = [];
    }

    setProps({
      value: matchValuesToOptions(options, nextValue),
    });
  };

  return {
    get value() {
      const { options, value } = props;

      return matchValuesToOptions(options, value);
    },

    set value(nextValue) {
      setValue(nextValue);
      runValidator();
    },

    get selectedIndices() {
      const { value, options } = props;

      return value.map(selected =>
        options.findIndex(option => option.value === selected),
      );
    },

    set selectedIndices(indices) {
      const { options } = props;

      if (assert.isNil(indices)) {
        indices = [];
      }

      const values = indices.map(index => options[index].value);

      setValue(values);
      runValidator();
    },

    get options() {
      return props.options.map(({ link, rel, value, label }) => ({
        ...(link && { link }),
        ...(rel && { rel }),
        ...(value && { value }),
        ...(label && { label }),
      }));
    },

    set options(nextOptions) {
      /**
       * User can enter anything into `link` property
       * of option.
       *
       * So we use `linkUtils.getLinkProps`, which during
       * converting into `LinkProps` will validate link
       * @param url
       */
      const getValidUrl = (url: string) => {
        const linkProps = linkUtils.getLinkProps(url, '_blank');
        return linkUtils.getLink(linkProps);
      };

      const withRequiredFields = rejectOptionsWithoutRequiredProperties(
        nextOptions,
      );

      const options = withRequiredFields.map(({ link, rel, value, label }) => ({
        ...(link && { link: getValidUrl(link) }),
        ...(rel && { rel }),
        value,
        label,
      }));

      setProps({ options });

      const { value } = getSdkInstance();

      /**
       * Setting SelectionTags.options removes any value that is not present in the new options.
       * It does, however, keep the values that are also present in the newly set options.
       * This is consistent with how "Multiple Choices" (AKA CheckboxGroup) behaves.
       *
       * TODO: after removing the experiment add test https://github.com/wix-private/editor-elements/pull/3055/files
       */

      setValue(matchValuesToOptions(options, value));
      runValidator();
    },

    get type() {
      return '$w.SelectionTags';
    },

    toJSON() {
      const { value, options, selectedIndices } = getSdkInstance();

      return {
        ...toJSONBase(metaData),
        value,
        options,
        selectedIndices,
      };
    },
  };
};

const factoryWithValidators = withValidation(
  selectionTagsListSDKFactory,
  validationSchema,
  customValidators,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  selectionTagsValidator,
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  selectionTagsValidator,
);

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  ISelectionTagsListProps,
  ISelectionTagsListSDK
>(
  elementPropsSDKFactory,
  stylePropsSDKFactory,
  changePropsSDKFactory,
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  factoryWithValidators,
);

export default createComponentSDKModel(sdk);
