import { parseMediaSrc } from '@wix/editor-elements-corvid-utils/src/media/mediaSrcHandler';
import { GridImageData, GridRow } from './Grid.types';
import { DEFAULT_ROWS_PER_PAGE } from './constants';

type DataPath = string | Array<string>;

const isObject = (value: unknown) =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export const extractImageDataFromSrc = (src: string): GridImageData | null => {
  const { error, title, mediaId: uri, width, height } = parseMediaSrc(
    src,
    'image',
  );

  if (error || !uri) {
    return null;
  }

  return { uri, width, height, title, name: title };
};

export const getRowValue = (row: GridRow, dataPath?: DataPath): any => {
  if (!dataPath) {
    return undefined;
  }

  const dataPathParts = Array.isArray(dataPath)
    ? dataPath
    : dataPath.split('.');

  let value = row;
  let index = 0;

  while (isObject(value) && index < dataPathParts.length) {
    value = value[dataPathParts[index++]];
  }

  return index && index === dataPathParts.length ? value : undefined;
};

export const setRowValue = (row: GridRow, dataPath: DataPath, value: any) => {
  const dataPathParts = Array.isArray(dataPath)
    ? dataPath
    : dataPath.split('.');

  let valueRef = row;
  let index = 0;

  while (valueRef && index < dataPathParts.length - 1) {
    if (!isObject(valueRef[dataPathParts[index]])) {
      valueRef[dataPathParts[index]] = {};
    }

    valueRef = valueRef[dataPathParts[index++]];
  }

  valueRef[dataPathParts[index]] = value;
};

export const getPageRowsRange = (
  page: number,
  rowsPerPage: number = DEFAULT_ROWS_PER_PAGE,
): [number, number] => {
  const startRow = (page - 1) * rowsPerPage;
  const endRow = startRow + rowsPerPage;
  return [startRow, endRow];
};

export function extractHtmlTagAttributes(attributesHtml: string) {
  const attributes: Record<string, string | undefined> = {};
  const attributesRegEx = /((\w*)(=(["']?)(((?!\4).)*)\4))/gi;
  let currAttr = attributesRegEx.exec(attributesHtml);

  while (currAttr !== null) {
    const name = currAttr[2].toLowerCase();
    const value = currAttr[5];
    attributes[name] = value;
    currAttr = attributesRegEx.exec(attributesHtml);
  }

  return attributes;
}

export const isValidDate = (date: Date) => !isNaN(date.getTime());

export const getLinkPropsPath = (linkPath: string) => `${linkPath}_$linkProps`;

export const getRichTextHtmlPath = (dataPath: string) => `${dataPath}_$html`;

export const removeProtocol = (url: string) => url.replace(/^https?:\/\//, '');
